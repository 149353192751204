import { ComponentType, FC, Suspense } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { appRootUrl, loginPagePath } from 'src/data/pageRoutes';
import SessionTimeout from 'src/modules/auth/modals/SessionTimeout';
import { useStoreSelector } from 'src/redux/hooks';
import PageLoader from '../PageLoader';

interface PrivateRouteGuardProps {
  component: ComponentType<any>;
}

const PrivateRouteGuard: FC<PrivateRouteGuardProps> = ({
  component: Component,
}) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const isLoggedIn = useStoreSelector(state => state['module/auth'].isLoggedIn);

  if (!isLoggedIn) {
    location?.pathname &&
      location?.pathname !== appRootUrl &&
      searchParams.set('redirect', location.pathname);
    return (
      <Navigate
        to={{ pathname: loginPagePath, search: searchParams.toString() }}
      />
    );
  }

  return (
    <Suspense fallback={<PageLoader message='Loading page...' />}>
      <SessionTimeout />
      <Component />
    </Suspense>
  );
};

export default PrivateRouteGuard;
