import { createSlice } from '@reduxjs/toolkit';
import { ReduxStore } from 'src/types';
import { fetchInvite } from './actions';

type InvitationReducerState = ReduxStore['module/invitation'];

const initialState: InvitationReducerState = {
  invite: null,
};

const invitationSlice = createSlice({
  name: 'module/invitation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchInvite.fulfilled, (state, action) => {
      state.invite = action.payload.data;
    });
  },
});

export const invitationReducer = invitationSlice.reducer;
