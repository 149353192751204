import AsyncWrapper from 'src/helpers/asyncWrapper';
import * as api from '../api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  RDTCompanyAddress,
  RDTDirectorStatus,
  RDTKeyPerson,
  RDTSecretaryStatus,
  RDTRemoveInvite,
  RDTSendInvite,
  RDTUpdateCompany,
  RDTUpdateKeyPerson,
  RDTChairmanStatus,
} from '../types';
import {
  CompanyAddress,
  CompanyInformation,
  Invite,
  KeyPerson,
  MessageResponse,
  OnboardingStatus,
  Payment,
  WithData,
} from 'src/types';

export const updateCompany = createAsyncThunk<
  WithData<CompanyInformation>,
  RDTUpdateCompany
>('onboarding/updateCompany', async ({ id, update }, { rejectWithValue }) => {
  const [data, error] = await AsyncWrapper(
    api.patchUpdateCompanyInformation(id, update, { include: 'industry' }),
  );
  if (error) return rejectWithValue(error.response?.data);
  return data;
});

export const fetchOnboardingStatus = createAsyncThunk<
  WithData<OnboardingStatus>,
  string
>('onboarding/fetchOnboardingStatus', async (id, { rejectWithValue }) => {
  const [data, error] = await AsyncWrapper(api.getOnboardingStatus(id));
  if (error) return rejectWithValue(error.response?.data);
  return data;
});

export const addCompanyAddress = createAsyncThunk<
  WithData<CompanyAddress>,
  RDTCompanyAddress
>('onboarding/addCompanyAddress', async (addressData, { rejectWithValue }) => {
  const [data, error] = await AsyncWrapper(api.postCompanyAddress(addressData));
  if (error) return rejectWithValue(error.response?.data);
  return data;
});

export const sendEmailInvite = createAsyncThunk<
  WithData<Invite>,
  RDTSendInvite
>(
  'onboarding/sendEmailInvite',
  async ({ cb, ...rest }, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(api.postSendInvite(rest));
    if (error) return rejectWithValue(error.response?.data);
    cb && cb();
    return data;
  },
);

export const removeInvite = createAsyncThunk<MessageResponse, RDTRemoveInvite>(
  'onboarding/removeInvite',
  async ({ id, cb }, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(api.deleteInvite(id));
    if (error) return rejectWithValue(error.response?.data);
    cb(data.message);
    return data;
  },
);

export const addKeyPerson = createAsyncThunk<WithData<KeyPerson>, RDTKeyPerson>(
  'onboarding/addKeyPerson',
  async ({ cb, ...rest }, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(api.postAddKeyPerson(rest));
    if (error) return rejectWithValue(error.response?.data);
    cb && cb();
    return data;
  },
);

export const updateKeyPerson = createAsyncThunk<
  WithData<KeyPerson>,
  RDTUpdateKeyPerson
>(
  'onboarding/updateKeyPerson',
  async ({ id, update, cb }, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(
      api.patchUpdateKeyPerson(id, update),
    );
    if (error) return rejectWithValue(error.response?.data);
    cb && cb();
    return data;
  },
);

export const removeKeyPerson = createAsyncThunk<MessageResponse, string>(
  'onboarding/removeKeyPerson',
  async (id, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(api.deleteKeyPerson(id));
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);

export const fetchCompanyTotalShares = createAsyncThunk<
  WithData<CompanyInformation>,
  string
>('onboarding/fetchCompanyTotalShares', async (id, { rejectWithValue }) => {
  const [data, error] = await AsyncWrapper(
    api.patchComputeCompanyTotalShares(id, {
      include: 'invites,kyc_key_people,industry',
    }),
  );
  if (error) return rejectWithValue(error.response?.data);
  return data;
});

export const updateDirectorStatus = createAsyncThunk<
  WithData<KeyPerson[]>,
  RDTDirectorStatus
>(
  'onboarding/updateDirectorStatus',
  async ({ cb, ...rest }, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(
      api.patchUpdateDirectorStatus(rest),
    );
    if (error) return rejectWithValue(error.response?.data);
    cb && cb();
    return data;
  },
);

export const updateChairmanStatus = createAsyncThunk(
  'onboarding/updateChairmanStatus',
  async (chairmanData: RDTChairmanStatus, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(
      api.patchUpdateChairmanStatus(chairmanData),
    );
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);

export const updateSecretaryStatus = createAsyncThunk(
  'onboarding/updateSecretaryStatus',
  async (secretaryData: RDTSecretaryStatus, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(
      api.patchUpdateSecretaryStatus(secretaryData),
    );
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);

export const fetchCompany = createAsyncThunk<
  WithData<CompanyInformation>,
  string
>('onboarding/fetchCompany', async (company, { rejectWithValue }) => {
  const [data, error] = await AsyncWrapper(
    api.getCompanySummary(company, {
      include: 'invites,kyc_key_people,industry,address,payments',
    }),
  );
  if (error) return rejectWithValue(error.response?.data);
  return data;
});

export const verifyPayment = createAsyncThunk<WithData<Payment>, string>(
  'onboarding/verifyPayment',
  async (paymentRef, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(api.getPaymentStatus(paymentRef));
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);
