import { Action, AnyAction } from '@reduxjs/toolkit';
import Cookies, { CookieAttributes } from 'js-cookie';
import { COOKIE_DOMAIN } from 'src/data/env';
import { generateCookieExpiry } from './genericUtils';

export type TokenKey =
  | 'auth'
  | 'otp'
  | 'accountLink'
  | 'sessionTimeoutOtp'
  | '*';

const AUTH_TOKEN_KEY = 'Brass/AuthToken';
const OTP_TOKEN_KEY = 'Brass/OtpToken';
const ACCOUNT_LINK_OTP_TOKEN_KEY = 'Brass/AccountLinkOtpToken';
const SESSION_TIMEOUT_OTP_TOKEN_KEY = 'Brass/SessionTimeoutOtpToken';

const TOKEN_ID_TO_KEY_MAP = {
  auth: AUTH_TOKEN_KEY,
  otp: OTP_TOKEN_KEY,
  accountLink: ACCOUNT_LINK_OTP_TOKEN_KEY,
  sessionTimeoutOtp: SESSION_TIMEOUT_OTP_TOKEN_KEY,
};

export const isUserLoggedIn = !!Cookies.get(TOKEN_ID_TO_KEY_MAP['auth']);

export const getToken = (id: TokenKey): string | undefined => {
  return Cookies.get(TOKEN_ID_TO_KEY_MAP[id]);
};

export const setToken = (id: TokenKey, token: string) => {
  const cookie = Cookies.get(TOKEN_ID_TO_KEY_MAP[id]);
  const config = getCookieConfig(id);
  if (cookie) {
    removeCookie(TOKEN_ID_TO_KEY_MAP[id], config);
  }
  return Cookies.set(TOKEN_ID_TO_KEY_MAP[id], token, config);
};

export const clearToken = (id: TokenKey): void => {
  if (id === '*') {
    return Object.keys(TOKEN_ID_TO_KEY_MAP).forEach(tokenId => {
      const config = getCookieConfig(tokenId as TokenKey);
      removeCookie(TOKEN_ID_TO_KEY_MAP[tokenId], config);
    });
  }
  const config = getCookieConfig(id);
  return removeCookie(TOKEN_ID_TO_KEY_MAP[id], config);
};

const removeCookie = (key: string, config: CookieAttributes) => {
  const cookie = Cookies.get(key);
  if (cookie) {
    Cookies.remove(key, config);
  }
};

const getCookieConfig = (id: TokenKey) => {
  const defaultConfig: CookieAttributes = {
    domain: COOKIE_DOMAIN,
    secure: process.env.NODE_ENV === 'production',
    expires: generateCookieExpiry(10),
  };
  if (TOKEN_ID_TO_KEY_MAP[id] === TOKEN_ID_TO_KEY_MAP.auth)
    defaultConfig.expires = 1;
  return defaultConfig;
};

export const isLogoutAction = (action: AnyAction): action is Action => {
  return action.type.endsWith('logout');
};
