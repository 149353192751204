import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariant } from 'brass-ui-kit';
import cx from 'classnames';

import { AlertIcon } from 'src/assets/svgs';

import styles from './ErrorBoundary.module.scss';
import { LAUNCHPAD_EMAIL_ADDRESS } from 'src/data/env';
import { sendEmail } from 'src/utils/genericUtils';

interface ErrorBoundaryProps {
  forRoute?: boolean;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ forRoute }) => {
  const navigate = useNavigate();

  const onReportClick = () => {
    sendEmail({
      email: LAUNCHPAD_EMAIL_ADDRESS,
      subject: `I encountered an error on Launchpad`,
      body: `I encountered an error on Launchpad. Please help!`,
    });
  };

  return (
    <div
      className={cx(styles.ErrorBoundary, {
        [styles.ErrorBoundary_for_route]: forRoute,
      })}
    >
      <AlertIcon />
      <h1>Something went wrong</h1>
      <p>There was a problem processing the request. Please try again</p>
      <p>
        To report an issue,{' '}
        <Button onClick={() => onReportClick()} variant={ButtonVariant.Text}>
          click here
        </Button>
      </p>
      <Button
        onClick={() => (forRoute ? navigate(-1) : window.location.reload())}
        variant={ButtonVariant.Primary}
      >
        {forRoute ? 'Return to last page' : 'Reload'}
      </Button>
    </div>
  );
};

export default ErrorBoundary;
