import {
  signUpNewUserRoute,
  loginRoute,
  resetPasswordRoute,
  resendVerificationRoute,
  changePasswordRoute,
  verifyEmailRoute,
  activeSessionRoute,
} from 'src/data/apiRoutes';
import BrassApiService from 'src/services/brassApiService';
import { IObject } from 'src/types';
import { RDTChangePassword, RDTUserLogin, RDTUserSignup } from './types';

export const postNewUser = (user: RDTUserSignup, params: IObject) =>
  BrassApiService.post(signUpNewUserRoute, user, { params });

export const patchResendVerification = (email: string) =>
  BrassApiService.patch(resendVerificationRoute, {
    email,
  });

export const postChangePassword = (
  user: RDTChangePassword,
  params: IObject,
) => {
  const { token, ...rest } = user;
  return BrassApiService.post(`${changePasswordRoute}/${token}`, rest, {
    params,
  });
};

export const patchVerifyEmail = (hash: string, params: IObject) =>
  BrassApiService.patch(`${verifyEmailRoute}/${hash}`, { params });

export const postLogin = (user: RDTUserLogin, params?: IObject) => {
  return BrassApiService.post(loginRoute, user, {
    params,
  });
};

export const postResetPassword = (email: string) => {
  return BrassApiService.post(resetPasswordRoute, { email });
};

export const getFetchActiveSession = () =>
  BrassApiService.get(activeSessionRoute, {
    headers: {
      'Cache-Control': 'no-cache',
    },
  });
