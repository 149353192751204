import { createSlice } from '@reduxjs/toolkit';
import { ReduxStore } from 'src/types';
import { isLogoutAction } from 'src/utils/authUtils';
import { fetchDashboardFaq } from './actions';

type FaqReducerState = ReduxStore['module/faq'];

const initialState: FaqReducerState = {
  dashboard: {
    data: null,
    loading: true,
  },
};

const faqSlice = createSlice({
  name: 'module/faq',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDashboardFaq.pending, state => {
        state.dashboard = { ...state.dashboard, loading: true };
      })
      .addCase(fetchDashboardFaq.rejected, state => {
        state.dashboard = { ...state.dashboard, loading: false };
      })
      .addCase(fetchDashboardFaq.fulfilled, (state, action) => {
        state.dashboard = {
          ...state.dashboard,
          ...action.payload,
          loading: false,
        };
      });
    builder.addMatcher(isLogoutAction, () => initialState);
  },
});

export const faqReducer = faqSlice.reducer;
