import BrassApiService from 'src/services/brassApiService';
import {
  statesListRoute,
  countriesListRoute,
  industriesRoute,
  companyTypesRoute,
} from 'src/data/apiRoutes';
import { RDTFetchLists, RDTFetchStatesList } from './types';

export const getStatesList = (params?: RDTFetchStatesList) =>
  BrassApiService.get(statesListRoute, { params });

export const getCountriesList = (params?: RDTFetchLists) =>
  BrassApiService.get(countriesListRoute, { params });

export const getIndustries = (params?: RDTFetchLists) =>
  BrassApiService.get(industriesRoute, { params });

export const getCompanyTypes = (params?: RDTFetchLists) =>
  BrassApiService.get(companyTypesRoute, { params });
