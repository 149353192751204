import { ButtonVariant } from 'brass-ui-kit';
import { BRASS_CDN_URL } from './env';
import { KeyPeople } from 'src/types';

export const REDUX_PERSIST_VERSION = 1;
export const REDUX_PERSIST_THROTTLE = 500;

export const BRASS_LAUNCH_MONTH = 8; // August

export const BRASS_LAUNCH_YEAR = 2020;

export const DEFAULT_SHARES_TOTAL = 1000000;

export const CURRENT_YEAR = new Date().getFullYear();

export const DATE_FORMAT = 'yyyy-MM-dd';

export const GENDER_OPTIONS = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
];

export const IDENTIFICATION_OPTIONS = [
  {
    label: 'International Passport',
    value: 'passport',
  },
  {
    label: `Driver's License`,
    value: 'drivers-license',
  },
  {
    label: `Voter's Card`,
    value: 'voters-id',
  },
  {
    label: `National ID Card`,
    value: 'national-identity',
  },
];

export const DIALOG_DATA_DEFAULTS = {
  title: '',
  description: '',
  successMessage: '',
  action: {
    variant: ButtonVariant.Primary,
    label: '',
  },
  request: async () => null,
};

export const SECRETARY_TYPES = [
  {
    label: 'Individual',
    value: 'individual',
  },
  {
    label: 'Corporate',
    value: 'corporate',
  },
];

export const TITLE_OPTIONS = [
  {
    label: 'Mr.',
    value: 'Mr.',
    gender: 'male',
  },
  {
    label: 'Mrs.',
    value: 'Mrs.',
    gender: 'female',
  },
  {
    label: 'Miss',
    value: 'Miss',
    gender: 'female',
  },
  {
    label: 'Sir.',
    value: 'Sir.',
    gender: 'male',
  },
  {
    label: 'Dame',
    value: 'Dame',
    gender: 'female',
  },
  {
    label: 'Dr.',
    value: 'Dr.',
  },
  {
    label: 'Barr.',
    value: 'Barr.',
  },
  {
    label: 'Prof.',
    value: 'Prof.',
  },
  {
    label: 'Hon.',
    value: 'Hon.',
  },
];

export const EBOOK_DOWNLOAD_STATUS_KEY = 'isEbookDownloaded';
export const COMMUNITY_STATUS_KEY = 'hasJoinedCommunity';

export const EBOOK_ASSET_URL = `${BRASS_CDN_URL}/assets/others/Brass_Ebook.pdf`;

export const PARTNER_TYPES = [
  KeyPeople.Proprietor,
  KeyPeople.Shareholder,
  KeyPeople.Trustee,
];
