import {
  differenceInHours,
  fromUnixTime,
  formatDistanceToNowStrict,
} from 'date-fns';
import fileDownload from 'js-file-download';
import { ToWords } from 'to-words';
import { notifySuccess, notifyError } from 'brass-ui-kit';
import * as Yup from 'yup';
import { isEmpty, isEqual, isPlainObject, transform } from 'lodash';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import { AddToCalendar, File, IObject } from 'src/types/generics';
import { AxiosRequestConfig } from 'axios';
import { google } from 'calendar-link';
import { BBCEvent } from 'src/modules/dashboard/types';

export const truncate = (text = '', length: number) =>
  text ? (text.length <= length ? text : `${text.substr(0, length)}...`) : '';

export const capitalize = (text = '') => {
  return text
    ? text
        .split(' ')
        .map(
          item =>
            `${item.charAt(0).toUpperCase()}${item.slice(1).toLowerCase()}`,
        )
        .join(' ')
    : '';
};

export const getDaysFromNow = (unix: number) => {
  const now = new Date();
  const dateToCompare = fromUnixTime(unix);
  return differenceInHours(dateToCompare, now) / 24;
};

export interface CurrentDate {
  month: string;
  year: string;
  day: string;
}

export const createDateString = ({ month, year, day }: CurrentDate) => {
  return `${year}-${month}-${day}`;
};

export const downloadFile = async ({ url, key = '', content_type }: File) => {
  const data = await fetch(url).then(res => res.blob());
  const filename = [...key.split('/')].pop() || '';
  fileDownload(data, filename, content_type);
};

export const viewFileUrl = async (url: string) => {
  window.open(url, '_blank');
};

export const numberFormatter = (options: Partial<Intl.NumberFormatOptions>) =>
  new Intl.NumberFormat('en-US', options);

export const koboToNaira = (
  koboValue: number,
  options: Partial<Intl.NumberFormatOptions> = { minimumFractionDigits: 2 },
) => numberFormatter(options).format(koboValue / 100);

export const convertNumberToWords = (number: number) => {
  const toWords = new ToWords({
    localeCode: 'en-US',
    converterOptions: {
      currency: false,
      ignoreDecimal: true,
    },
  });
  return toWords.convert(number);
};

const getShort = (value: number, exp: number, char: string) => {
  return parseFloat((value / Math.pow(10, exp)).toFixed(1)).toString() + char;
};

export const shortenAmount = (value: number) => {
  const absValue = Math.abs(value);
  let a = '';

  if (absValue >= Math.pow(10, 12)) {
    a = getShort(value, 12, 'T');
  } else if (absValue >= Math.pow(10, 9)) {
    a = getShort(value, 9, 'B');
  } else if (absValue >= Math.pow(10, 6)) {
    a = getShort(value, 6, 'M');
  } else if (absValue >= Math.pow(10, 3)) {
    a = getShort(value, 3, 'K');
  }

  return a;
};

export const copyText = async ({
  text,
  successMessage = 'Copied to clipboard',
  errorMessage = 'Oops!! Unable to copy to clipboard',
}: {
  text: string;
  successMessage?: string;
  errorMessage?: string;
}) => {
  try {
    if (!!navigator.clipboard && !!navigator.clipboard.writeText) {
      await navigator.clipboard.writeText(text);
    }

    notifySuccess(successMessage);
  } catch (error) {
    notifyError(errorMessage);
  }
};

export function clean<T = any>(object: T) {
  return transform<any, T>(object, (result, value, key) => {
    // Recurse into arrays and objects.
    if (Array.isArray(value) || isPlainObject(value)) {
      value = clean(value);
    }

    // Exclude empty objects.
    if (isPlainObject(value) && isEmpty(value)) {
      return;
    }

    // Exclude empty arrays.
    if (Array.isArray(value) && !value.length) {
      return;
    }

    // Exclude empty strings.
    if (value === '') {
      return;
    }

    // Exclude NaN values.
    if (Number.isNaN(value)) {
      return;
    }

    // Exclude null values.
    if (value === null) {
      return;
    }

    // Exclude undefined values.
    if (value === undefined) {
      return;
    }

    // Append when recursing arrays.
    if (Array.isArray(result)) {
      return result.push(value);
    }

    result[key] = value;
  });
}

export const convertImageToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        resolve({ imageURL: e?.target?.result });
      };
      reader.onerror = function () {
        reject(new Error('Image conversion failed'));
      };
      reader.readAsDataURL(file);
    } else {
      reject(new Error('Image is empty'));
    }
  });
};

export function objectDifference<T = IObject>(oldObj: T, newObj: T) {
  return [...Object.keys(oldObj)].reduce((acc: T, key: string): T => {
    if (newObj[key] && !isEqual(newObj[key], oldObj[key])) {
      acc[key] = newObj[key];
    }
    return acc;
  }, {} as T);
}

const isValidCountryCode = (countryCode: any): boolean =>
  typeof countryCode === 'string' && countryCode.length === 2;

export const validatePhoneNumber = (
  errorMessage: string,
  options = {
    countryCode: 'NG',
  },
) => {
  return Yup.string().test(
    'is-phone-number-valid',
    errorMessage,
    (value?: string | null) => {
      if (!value) return true;
      if (!isValidCountryCode(options.countryCode)) return false;
      try {
        if (parsePhoneNumber) {
          const phoneNumber = parsePhoneNumber(
            value,
            options.countryCode.toUpperCase() as CountryCode,
          );

          return !!phoneNumber?.isValid();
        }
        return false;
      } catch (error) {
        return false;
      }
    },
  );
};

export const getFilenameFromUrl = (url: string) => {
  if (!url) return '';
  return url?.split('/')?.splice(-2, 2)?.join('/') || '';
};

export const paramsToObject = (entries: any) => {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
};

export const generateCookieExpiry = (expiryInMins: number) =>
  1 / ((60 * 24) / expiryInMins);

export const getDueDateInWords = (due_at: string) =>
  formatDistanceToNowStrict(new Date(due_at), {
    addSuffix: true,
    roundingMethod: 'floor',
  }).toUpperCase();

export const removeItemByPropertyKey = <T>(
  key: string,
  arr: T[],
  items: T[],
) => {
  const itemsPropertyValue = items?.map(item => item[key]);
  return arr?.filter(item => !itemsPropertyValue.includes(item[key]));
};

export const getSleepScoreRadialColor = (score: number) => {
  if (score < 70) {
    return '#F21414';
  }
  if (score < 80) {
    return '#F8EBAD';
  }
  return '#265AEA';
};

export const setRequestHeaders = (
  req: AxiosRequestConfig,
  headers: IObject<any>,
) => ({
  ...req,
  headers: { ...req?.headers, ...headers },
});

export const getCompanyTypeKey = (CompanyType: IObject<any>) => {
  const companyTypeLabel = CompanyType?.label;
  return `${companyTypeLabel}`.split(' ').join('_');
};

export const handleAddCalender = (values: BBCEvent) => {
  const oneHour = 60 * 60 * 1000;
  const endDate = new Date(values.date).getTime() + oneHour;
  const event: AddToCalendar = {
    title: values.title,
    description: values.description,
    start: new Date(values.date),
    end: new Date(endDate),
  };

  return google(event);
};

export const sendEmail = (message: {
  email: string;
  body: string;
  subject: string;
  cc?: string;
}) => {
  const email = message.email;
  const subject = message.subject;
  const emailBody = message.body;
  const body = document.querySelector('body');
  const link = `mailto:${email}?subject=${subject}&body=${emailBody}${
    message.cc ? `&cc=${message.cc}` : ''
  }`;
  const trigger = document.createElement('a');
  trigger.setAttribute('href', link);
  trigger.setAttribute('target', '_blank');
  trigger.setAttribute('rel', 'noreferrer');
  trigger.setAttribute('style', 'visibility:hidden');
  body?.appendChild(trigger);
  trigger.click();
  body?.removeChild(trigger);
};
