import React from 'react';
import {
  Flyout as BrassUiKitFlyout,
  Dropdown,
  Button,
  ButtonVariant,
  FlyoutSize,
} from 'brass-ui-kit';
import cx from 'classnames';
import { OptionIcon } from 'src/assets/svgs';
import { DropDownOption, FooterProps, FunctionalComponent } from 'src/types';

import styles from './Flyout.module.scss';

interface FlyoutProps {
  title?: string;
  description?: string;
  show: boolean;
  noHeader?: boolean;
  isMaskOn?: boolean;
  isMaskClear?: boolean;
  handleClose?: () => void;
  className?: string;
  contentClassName?: string;
  size?: FlyoutSize | number;
  options?: DropDownOption[];
  footer?: FooterProps;
  renderFooter?: () => React.ReactNode;
}

const Flyout: FunctionalComponent<FlyoutProps> = ({
  children,
  title,
  description,
  show,
  handleClose,
  className,
  noHeader,
  contentClassName,
  options,
  footer,
  renderFooter,
  ...props
}) => {
  const showOptions =
    !!options?.length && !!options.filter(({ isHidden }) => !isHidden).length;

  const renderFlyoutFooter = () => {
    if (footer && !renderFooter)
      return (
        <footer>
          {footer['secondary'] && (
            <Button
              variant={ButtonVariant.Secondary}
              {...footer['secondary'].props}
            >
              {footer['secondary'].label}
            </Button>
          )}
          {footer['primary'] && (
            <Button
              variant={ButtonVariant.Primary}
              {...footer['primary'].props}
            >
              {footer['primary'].label}
            </Button>
          )}
        </footer>
      );

    if (!footer && renderFooter) return renderFooter();

    return null;
  };
  return (
    <BrassUiKitFlyout
      isOpen={show}
      closeFlyout={handleClose}
      className={styles.Flyout}
      size={FlyoutSize.Medium}
      contentClassName={cx(className, styles.Flyout_content)}
      {...props}
    >
      {showOptions && (
        <Dropdown options={options || []} className={styles.Flyout_options}>
          <OptionIcon />
        </Dropdown>
      )}
      {!noHeader && (
        <header>
          {title && <h2 className={styles.Flyout_title}>{title}</h2>}
          {description && (
            <p className={styles.Flyout_description}>{description}</p>
          )}
        </header>
      )}
      <div
        className={cx(styles.Flyout_content_body, contentClassName, {
          [styles.Flyout_content_body_full]: noHeader,
        })}
      >
        {children}
      </div>
      {renderFlyoutFooter()}
    </BrassUiKitFlyout>
  );
};

Flyout.defaultProps = {
  noHeader: false,
  isMaskOn: true,
  isMaskClear: true,
};

export default Flyout;
