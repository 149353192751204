import React from 'react';
import { FunctionalComponent } from 'src/types';

type IfProps = {
  condition?: boolean;
};

const If: FunctionalComponent<IfProps> = ({ condition, children }) => {
  return condition ? <>{children}</> : null;
};

export default If;
