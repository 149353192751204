import axios from 'src/helpers/axios';

import { BRASS_CMS_URL } from 'src/data/env';

const BrassCmsService = axios.create({
  baseURL: BRASS_CMS_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default BrassCmsService;
