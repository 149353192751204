import axios, { AxiosRequestConfig } from 'axios';
import localforage from 'localforage';
import { setupCache, buildStorage } from 'axios-cache-interceptor';

class Axios {
  //Create axios instance and set up cache
  create(config?: AxiosRequestConfig<any>) {
    //Cache storage using localforage (indexDB wrapper with polyfill)
    const storage = buildStorage({
      async find(key) {
        const result: any = await localforage.getItem(`axios-cache:${key}`);
        return result;
      },

      async set(key, value) {
        await localforage.setItem(`axios-cache:${key}`, value);
      },

      async remove(key) {
        await localforage.removeItem(`axios-cache:${key}`);
      },
    });

    //Cache expiration
    const ttl = 1000 * 60 * 1;

    //Cachable methods
    const methods = ['get'] as any;

    return setupCache(axios.create(config), {
      storage,
      ttl,
      interpretHeader: true,
      methods,
      cachePredicate: {
        statusCheck: status => status >= 200 && status < 400,
      },
      modifiedSince: false,
      staleIfError: true,
      debug: console.log,
    });
  }
}

export default new Axios();
