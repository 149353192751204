import { AnyAction, combineReducers, Reducer } from 'redux';
import { commonReducer } from '../modules/common/redux';
import { authReducer } from 'src/modules/auth/redux';
import { ReduxStore } from 'src/types';
import storage from 'src/helpers/localStorage';
import { BRASS_LAUNCHPAD_REDUX_PERSIST_KEY } from 'src/data/env';
import { onboardingReducer } from 'src/modules/onboarding/redux';
import { dashboardReducer } from 'src/modules/dashboard/redux';
import { invitationReducer } from 'src/modules/invitation/redux';
import { faqReducer } from 'src/modules/faq';

export default ((state: ReduxStore, action: AnyAction) => {
  if (action.type === 'module/auth/logout') {
    storage.remove(`persist:${BRASS_LAUNCHPAD_REDUX_PERSIST_KEY}`);
  }
  return combineReducers({
    'module/common': commonReducer,
    'module/auth': authReducer,
    'module/onboarding': onboardingReducer,
    'module/dashboard': dashboardReducer,
    'module/invitation': invitationReducer,
    'module/faq': faqReducer,
  })(state, action);
}) as Reducer;
