import axios from 'src/helpers/axios';
import { getToken } from 'src/utils/authUtils';
import { setRequestHeaders } from 'src/utils/genericUtils';
import * as env from 'src/data/env';
import { omit } from 'lodash';

const BrassServices = axios.create({
  baseURL: env.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

BrassServices.interceptors.request.use(req => {
  const apiKey = env.API_KEY;
  const authToken = getToken('auth');
  const useApiKey = req.params?.useApiKey;

  if (useApiKey && apiKey) {
    req.params = omit(req.params, ['useApiKey']);
    return setRequestHeaders(req, { Authorization: `Bearer ${apiKey}` });
  }

  if (authToken) {
    req = setRequestHeaders(req, { Authorization: `Bearer ${authToken}` });
  } else {
    req = setRequestHeaders(req, { Authorization: `Bearer ${apiKey}` });
  }

  if (process.env.NODE_ENV !== 'production') {
    req.params = Object.assign(req.params || {}, { debug_mode: 1 });
  }

  return req;
});

export default BrassServices;
