//App root URL
export const appRootUrl = `/`;

//404 URL
export const notFoundPagePath = '/404';

// Authentication URLs
export const authSlug = `auth`;
export const authPath = `/${authSlug}`;
export const signupPageSlug = `signup`;
export const signupPagePath = `${authPath}/${signupPageSlug}`;
export const loginPageSlug = `login`;
export const loginPagePath = `${authPath}/${loginPageSlug}`;
export const resetPasswordPageSlug = `reset`;
export const resetPasswordPagePath = `${authPath}/${resetPasswordPageSlug}`;
export const setPasswordPageSlug = `change-password`;
export const setPasswordPagePath = `${authPath}/${setPasswordPageSlug}`;
export const confirmEmailPageSlug = `email-verification`;
export const confirmEmailPageePath = `${authPath}/${confirmEmailPageSlug}`;

//Monitoring URLs
export const monitoringPageUrl = '/';

// Onboarding URLs
export const onboardingSlug = `onboarding`;
export const onboardingPath = `/${onboardingSlug}`;
export const aboutSlug = `about`;
export const aboutPath = `${onboardingPath}/${aboutSlug}`;
export const companyTypeSlug = `company-type`;
export const companyTypePath = `${onboardingPath}/${companyTypeSlug}`;
export const companyDetailsSlug = `company-details`;
export const companyDetailsPath = `${onboardingPath}/${companyDetailsSlug}`;
export const companyAddressSlug = `address`;
export const companyAddressPath = `${onboardingPath}/${companyAddressSlug}`;
export const shareholdersSlug = `shareholders`;
export const shareholdersPath = `${onboardingPath}/${shareholdersSlug}`;
export const proprietorsSlug = `proprietors`;
export const proprietorsPath = `${onboardingPath}/${proprietorsSlug}`;
export const trusteesSlug = `trustees`;
export const trusteesPath = `${onboardingPath}/${trusteesSlug}`;
export const trusteesChairmanSlug = `trustees-chairman`;
export const trusteesChairmanPath = `${onboardingPath}/${trusteesChairmanSlug}`;
export const ownershipSlug = `ownership`;
export const ownershipPath = `${onboardingPath}/${ownershipSlug}`;
export const directorsSlug = `directors`;
export const directorsPath = `${onboardingPath}/${directorsSlug}`;
export const secretarySlug = `secretary`;
export const secretaryPath = `${onboardingPath}/${secretarySlug}`;
export const reviewSlug = `review`;
export const reviewPath = `${onboardingPath}/${reviewSlug}`;
export const paymentSlug = `payment`;
export const paymentPath = `${onboardingPath}/${paymentSlug}`;
export const pendingRequestsSlug = `pending-requests`;
export const pendingRequestsPath = `${onboardingPath}/${pendingRequestsSlug}`;

export const invitationPagePath = '/invitation';
export const keyPersonResponseSlug = 'key-person/setup';
export const keyPersonResponsePath = `${invitationPagePath}/${keyPersonResponseSlug}`;

// Dashboard URLs
export const dashboardSlug = `dashboard`;
export const dashboardPath = `/${dashboardSlug}`;
export const dashboardHomeSlug = 'home';
export const dashboardHomePath = `${dashboardPath}/${dashboardHomeSlug}`;
export const documentsSlug = 'documents';
export const documentsPath = `${dashboardPath}/${documentsSlug}`;
export const resourcesSlug = 'resources';
export const resourcesPath = `${dashboardPath}/${resourcesSlug}`;
export const rewardsSlug = 'rewards';
export const rewardsPath = `${dashboardPath}/${rewardsSlug}`;
export const rewardSlug = `${rewardsSlug}/:id`;
