import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CompanyInformation,
  KeyPeople,
  KeyPerson,
  ReduxStore,
} from 'src/types';
import { isLogoutAction } from 'src/utils/authUtils';
import {
  addCompanyAddress,
  addKeyPerson,
  fetchCompany,
  removeInvite,
  removeKeyPerson,
  sendEmailInvite,
  updateCompany,
  updateKeyPerson,
  updateDirectorStatus,
  updateSecretaryStatus,
  fetchOnboardingStatus,
  verifyPayment,
  updateChairmanStatus,
} from './actions';
import { PARTNER_TYPES } from 'src/data/constants';

type OnboardingReducerState = ReduxStore['module/onboarding'];

const initialState: OnboardingReducerState = {
  activeRouteIndex: 0,
  activeCompanyType: null,
  companyInformation: null,
  companyAddress: null,
  keyPeople: null,
  invites: null,
  onboardingStatus: null,
  isVerifyingPayment: false,
};

const onboardingSlice = createSlice({
  name: 'module/onboarding',
  initialState,
  reducers: {
    setCompanyType: (state, action: PayloadAction<string>) => {
      state.activeCompanyType = action.payload;
    },
    setActiveRouteIndex: (state, action: PayloadAction<number>) => {
      state.activeRouteIndex = action.payload;
    },
    setCompanyData: (state, action: PayloadAction<CompanyInformation>) => {
      state.activeCompanyType = action.payload.company_type.data.id;
      state.companyInformation = action.payload;
      state.companyAddress = action.payload.address?.data!;
    },
    deleteKeyPerson: (state, action: PayloadAction<string>) => {
      state.keyPeople =
        state.keyPeople?.filter(keyPerson => keyPerson.id !== action.payload) ||
        [];
    },
  },
  extraReducers: builder => {
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      state.companyInformation = action.payload.data;
      state.activeCompanyType = action.payload.data.company_type.data.id;
      state.activeRouteIndex = state.activeRouteIndex + 1;
    });
    builder.addCase(addCompanyAddress.fulfilled, (state, action) => {
      state.companyAddress = action.payload.data;
      state.activeRouteIndex = state.activeRouteIndex + 1;
    });
    builder.addCase(sendEmailInvite.fulfilled, (state, action) => {
      state.invites = state.invites
        ? [...state.invites, action.payload.data]
        : [action.payload.data];
    });
    builder.addCase(removeInvite.fulfilled, (state, action) => {
      state.invites =
        state.invites?.filter(invite => invite.id !== action.meta.arg.id) || [];
    });
    builder.addCase(addKeyPerson.fulfilled, (state, action) => {
      state.keyPeople = state.keyPeople
        ? [...state.keyPeople, action.payload.data]
        : [action.payload.data];
    });
    builder.addCase(updateKeyPerson.fulfilled, (state, action) => {
      state.keyPeople =
        state.keyPeople?.map(person => {
          return person.id === action.payload.data.id
            ? { ...person, ...action.payload.data }
            : person;
        }) || [];
    });
    builder.addCase(removeKeyPerson.fulfilled, (state, action) => {
      state.keyPeople =
        state.keyPeople?.filter(
          keyPerson => keyPerson.id !== action.meta.arg,
        ) || [];
    });
    builder.addCase(fetchCompany.fulfilled, (state, action) => {
      const { invites, kyc_key_people, address, ...rest } = action.payload.data;
      return Object.assign(state, {
        companyInformation: rest,
        companyAddress: address?.data,
        keyPeople: kyc_key_people?.data,
        invites: invites?.data,
      });
    });
    builder.addCase(fetchOnboardingStatus.fulfilled, (state, action) => {
      state.onboardingStatus = action.payload.data;
    });
    builder.addCase(updateDirectorStatus.fulfilled, (state, action) => {
      return Object.assign(state, {
        keyPeople: state.keyPeople?.map(
          person =>
            action.payload.data.find(
              (updatedPerson: KeyPerson) => updatedPerson.id === person.id,
            ) || person,
        ),
      });
    });
    builder
      .addCase(updateSecretaryStatus.fulfilled, (state, action) => {
        return Object.assign(state, {
          keyPeople: state.keyPeople?.map(person => {
            if (person.id === action.payload.data.id) {
              return { ...person, ...action.payload.data };
            }
            return { ...person, is_secretary: false };
          }),
        });
      })
      .addCase(verifyPayment.pending, state => {
        return Object.assign(state, {
          isVerifyingPayment: true,
        });
      })
      .addCase(verifyPayment.rejected, state => {
        return Object.assign(state, {
          isVerifyingPayment: false,
        });
      })
      .addCase(updateChairmanStatus.fulfilled, (state, action) => {
        return Object.assign(state, {
          keyPeople: state.keyPeople?.map(person => {
            if (person.id === action.payload.data.id) {
              return { ...person, ...action.payload.data };
            }
            return { ...person, is_chairman: false };
          }),
        });
      })
      .addCase(verifyPayment.fulfilled, (state, action) => {
        return Object.assign(state, {
          companyInformation: {
            ...state.companyInformation,
            payments: { data: [action.payload.data] },
          },
          isVerifyingPayment: false,
        });
      });
    builder.addMatcher(isLogoutAction, () => initialState);
  },
});

export const onboardingReducer = onboardingSlice.reducer;

export const selectCompanyType = (state: ReduxStore) => {
  return (
    state['module/onboarding'].companyInformation?.company_type.data || null
  );
};

export const selectKeyPeopleAndInvites = (state: ReduxStore) => {
  const directorInvites =
    state['module/onboarding'].invites?.filter(
      invite => invite.is_director && invite.status === 'pending',
    ) || [];

  const partnerInvites =
    state['module/onboarding'].invites?.filter(
      invite =>
        PARTNER_TYPES.includes(invite.role as KeyPeople) &&
        invite.status === 'pending',
    ) || [];
  const directors =
    state['module/onboarding'].keyPeople?.filter(
      person => person.is_director,
    ) || [];
  const partners =
    state['module/onboarding'].keyPeople?.filter(person =>
      PARTNER_TYPES.includes(person.role as KeyPeople),
    ) || [];

  const secretary =
    state['module/onboarding'].keyPeople?.find(person => person.is_secretary) ||
    null;

  return {
    directorInvites,
    partnerInvites,
    directors,
    partners,
    secretary,
  };
};

export const {
  setCompanyType,
  setCompanyData,
  setActiveRouteIndex,
  deleteKeyPerson,
} = onboardingSlice.actions;
