import React, { useState } from 'react';
import { TextInput, TextInputProps } from 'brass-ui-kit';
import cx from 'classnames';

import styles from './PasswordInput.module.scss';

const PasswordInput: React.FC<TextInputProps> = props => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextInput
      {...props}
      type={showPassword ? 'text' : 'password'}
      wrapperClassName={styles.PasswordInput_wrapper}
    >
      <span
        className={cx(styles.PasswordInput_status, {
          [styles.PasswordInput_withLabel_status]: !!props.label,
        })}
        role='button'
        tabIndex={0}
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        {showPassword ? 'Hide' : 'Show'}
      </span>
    </TextInput>
  );
};

PasswordInput.defaultProps = {
  label: 'Password',
  name: 'password',
  placeholder: 'Password (min. of 8 characters)',
};

export default PasswordInput;
