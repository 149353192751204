//Auth routes
export const signUpNewUserRoute = '/auth/register';
export const resendVerificationRoute = '/auth/email/verifications';
export const changePasswordRoute = '/auth/passwords/resets';
export const verifyEmailRoute = '/auth/email/verify';
export const loginRoute = '/auth/login?include=industry,address';
export const activeSessionRoute = '/auth/me';
export const resetPasswordRoute = '/auth/passwords/resets';

//Common routes
export const companyTypesRoute = '/company-types';
export const industriesRoute = '/industries';
export const statesListRoute = '/states';
export const countriesListRoute = '/countries';

// Onboarding routes
export const onboardingRoute = '/onboarding';
export const onboardingNameCheckRoute = `${onboardingRoute}/business-names/enquiry`;
export const onboardingCompanyRoute = `${onboardingRoute}/companies`;
export const onboardingAddressRoute = `${onboardingRoute}/addresses`;
export const onboardingInviteRoute = `${onboardingRoute}/invites`;
export const onboardingPaymentRoute = `${onboardingRoute}/payments`;
export const keyPeopleRoute = `${onboardingRoute}/people`;
export const keyPeopleSharesRoute = `${keyPeopleRoute}/shares`;
export const keyPeopleDirectorsRoute = `${keyPeopleRoute}/directors`;
export const keyPeopleSecretaryRoute = `${keyPeopleRoute}/secretary`;
export const keyPeopleChairmanRoute = `${keyPeopleRoute}/chairman`;
export const onboardingCompleteRoute = `${onboardingRoute}/complete`;

// Dashboard routes
export const issuesRoute = '/core/issues';
export const rewardsRoute = '/launchpad-rewards';
export const templatesRoute = '/launchpad-templates';
export const ebooksRoute = '/ebooks';
export const eventsRoute = '/bbc-events';

// FAQ routes
export const faqRoute = '/faqs';
