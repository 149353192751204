import React, { useState } from 'react';
import { Button, ButtonVariant, Modal } from 'brass-ui-kit';
import { SessionTimeoutActiveStepType } from '../../types';
import { getToken } from 'src/utils/authUtils';

import styles from './SessionTimeout.module.scss';
import { useStoreDispatch, useStoreSelector } from 'src/redux/hooks';
import LoginPage from '../../pages/Login';
import TimeoutComponentLayout from '../../layouts/TimeoutComponentLayout';
import { refreshSessionToken } from '../../functions';
import { setSessionTimeout } from '../../redux';

const SessionTimeout: React.FC = () => {
  const { hasSessionTimedOut, isLoggedIn } = useStoreSelector(
    state => state['module/auth'],
  );

  const [activeStep, setActiveStep] = useState<SessionTimeoutActiveStepType>(
    SessionTimeoutActiveStepType.Start,
  );
  const isAuthTokenInStorage = getToken('auth');

  const showModal = React.useMemo(
    () => hasSessionTimedOut && (isLoggedIn || !!isAuthTokenInStorage),
    [hasSessionTimedOut, isLoggedIn, isAuthTokenInStorage],
  );

  const dispatch = useStoreDispatch();

  if (!showModal) return null;

  return (
    <Modal
      isOpen={showModal}
      closeModal={() => null}
      className={styles.SessionTimeout_backdrop}
      contentClassName={styles.SessionTimeout}
    >
      <TimeoutComponentLayout>
        {activeStep === SessionTimeoutActiveStepType.Start && (
          <div className={styles.SessionTimeout_card}>
            <h1 className={styles.SessionTimeout_card_ttl}>
              Your session timed out!
            </h1>
            <p className={styles.SessionTimeout_card_desc}>
              You have been logged out of Brass due to inactivity.
              <br />
              Please log in again to continue using Brass.
            </p>
            <Button
              className={styles.SessionTimeout_card_btn}
              variant={ButtonVariant.Primary}
              onClick={() => setActiveStep(SessionTimeoutActiveStepType.Login)}
            >
              Log In
            </Button>
          </div>
        )}
        {activeStep === SessionTimeoutActiveStepType.Login && (
          <div className={styles.SessionTimeout_form}>
            <LoginPage
              customHandleLogin={user =>
                refreshSessionToken(user, () =>
                  dispatch(setSessionTimeout(false)),
                )
              }
              asEmbed
            />
          </div>
        )}
      </TimeoutComponentLayout>
    </Modal>
  );
};

export default SessionTimeout;
