import { AxiosError, AxiosResponse } from 'axios';
import { handleError } from 'src/utils/errorHandlerUtils';

export default async (
  promise: Promise<AxiosResponse<any, any>>,
  errorHandler?: (e: AxiosError<any>) => void,
) => {
  const result: [AxiosResponse<any, any>['data'], AxiosError<any> | null] = [
    null,
    null,
  ];
  try {
    const payload = await promise;
    result[0] = await payload.data;
  } catch (e: any) {
    result[1] = e;
    if (errorHandler) {
      errorHandler(e);
    } else {
      handleError(e);
    }
  }
  return result;
};
