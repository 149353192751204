export const ENV: string = process.env.NODE_ENV!;

export const API_URL: string = process.env.REACT_APP_API_URL!;

export const API_KEY: string = process.env.REACT_APP_API_KEY!;

export const BRASS_CDN_URL = process.env.REACT_APP_BRASS_CDN_URL!;

export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN!;

export const SENTRY_KEY = process.env.REACT_APP_SENTRY_PUBLIC_KEY;

export const BRASS_LAUNCHPAD_REDUX_PERSIST_KEY =
  process.env.REACT_APP_BRASS_LAUNCHPAD_REDUX_PERSIST_KEY! || 'DEMO_KEY';

export const BRASS_HOMEPAGE_URL: string =
  process.env.REACT_APP_BRASS_HOMEPAGE_URL!;
export const LAUNCHPAD_HOMEPAGE_URL: string =
  process.env.REACT_APP_LAUNCHPAD_HOMEPAGE_URL!;
export const LAUNCHPAD_EMAIL_ADDRESS: string =
  process.env.REACT_APP_LAUNCHPAD_EMAIL_ADDRESS!;
export const BBC_HOMEPAGE_URL: string = process.env.REACT_APP_BBC_HOMEPAGE_URL!;
export const BBC_TWITTER_URL: string = process.env.REACT_APP_BBC_TWITTER_URL!;

export const SENTRY_TRACES_SAMPLE_RATE = process.env
  ?.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
  ? Number(process.env?.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
  : 0;

export const SENTRY_ENV = process.env?.REACT_APP_SENTRY_ENV || ENV;

export const BRASS_PRIVACY_POLICY = process.env.REACT_APP_BRASS_PRIVACY_POLICY!;

export const BRASS_TERMS_CONDITIONS =
  process.env.REACT_APP_BRASS_TERMS_CONDITIONS!;

export const AIRTABLE_API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY!;

export const AIRTABLE_BASE_KEY = process.env.REACT_APP_AIRTABLE_BASE_KEY!;

export const AIRTABLE_BASE_NAME = process.env.REACT_APP_AIRTABLE_BASE_NAME!;

export const BRASS_CMS_URL: string = process.env.REACT_APP_BRASS_CMS_URL!;
