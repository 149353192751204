import AsyncWrapper from 'src/helpers/asyncWrapper';
import * as api from '../api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RDTUserLogin } from '../types';

export const login = createAsyncThunk(
  'auth/login',
  async (user: RDTUserLogin, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(
      api.postLogin(user, { useApiKey: true }),
    );
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);

export const fetchActiveSession = createAsyncThunk(
  'auth/fetchActiveSession',
  async (_, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(api.getFetchActiveSession());
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);
