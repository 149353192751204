import BrassApiService from 'src/services/brassApiService';

import {
  keyPeopleChairmanRoute,
  keyPeopleDirectorsRoute,
  keyPeopleRoute,
  keyPeopleSecretaryRoute,
  keyPeopleSharesRoute,
  onboardingAddressRoute,
  onboardingCompanyRoute,
  onboardingCompleteRoute,
  onboardingInviteRoute,
  onboardingNameCheckRoute,
  onboardingPaymentRoute,
  onboardingRoute,
} from 'src/data/apiRoutes';
import {
  RDTAllocateShares,
  RDTChairmanStatus,
  RDTCompanyAddress,
  RDTCompanyInformation,
  RDTDirectorStatus,
  RDTKeyPerson,
  RDTSecretaryStatus,
  RDTSendInvite,
} from './types';
import { DefaultFetchParams } from 'src/types';

export const postCompanyNameCheck = (businessName: string) =>
  BrassApiService.post(onboardingNameCheckRoute, {
    businessName,
  });

export const postCompanyInformation = (data: Partial<RDTCompanyInformation>) =>
  BrassApiService.post(onboardingCompanyRoute, data);

export const patchUpdateCompanyInformation = (
  id: string,
  data: Partial<RDTCompanyInformation>,
  params: DefaultFetchParams,
) =>
  BrassApiService.patch(`${onboardingCompanyRoute}/${id}`, data, {
    params,
  });

export const postCompanyAddress = (data: RDTCompanyAddress) =>
  BrassApiService.post(onboardingAddressRoute, data);

export const postSendInvite = (data: RDTSendInvite) =>
  BrassApiService.post(onboardingInviteRoute, data);

export const postSendInviteReminder = (id: string) =>
  BrassApiService.post(`${onboardingInviteRoute}/${id}`);

export const deleteInvite = (id: string) =>
  BrassApiService.delete(`${onboardingInviteRoute}/${id}`);

export const postAddKeyPerson = (data: RDTKeyPerson) =>
  BrassApiService.post(keyPeopleRoute, data);

export const patchUpdateKeyPerson = (id: string, data: Partial<RDTKeyPerson>) =>
  BrassApiService.patch(`${keyPeopleRoute}/${id}`, data);

export const deleteKeyPerson = (id: string) =>
  BrassApiService.delete(`${keyPeopleRoute}/${id}`);

export const getCompanySummary = (id: string, params: DefaultFetchParams) =>
  BrassApiService.get(`${onboardingCompanyRoute}/${id}`, {
    params,
  });

export const getOnboardingStatus = (id: string) =>
  BrassApiService.get(`${onboardingRoute}`, {
    params: { for: id },
  });

export const patchAllocateShares = (data: RDTAllocateShares) =>
  BrassApiService.patch(`${keyPeopleSharesRoute}`, data);

export const patchComputeCompanyTotalShares = (
  id: string,
  params: DefaultFetchParams,
) =>
  BrassApiService.patch(
    `${onboardingCompanyRoute}/${id}/share-capital`,
    {},
    { params },
  );

export const patchUpdateDirectorStatus = (data: RDTDirectorStatus) =>
  BrassApiService.patch(`${keyPeopleDirectorsRoute}`, data);

export const patchUpdateSecretaryStatus = (data: RDTSecretaryStatus) =>
  BrassApiService.patch(`${keyPeopleSecretaryRoute}`, data);

export const patchUpdateChairmanStatus = (data: RDTChairmanStatus) =>
  BrassApiService.patch(`${keyPeopleChairmanRoute}`, data);

export const postInitiatePayment = (company_id: string) =>
  BrassApiService.post(`${onboardingPaymentRoute}`, { for: company_id });

export const getPaymentStatus = (paymentRef: string) =>
  BrassApiService.get(`${onboardingPaymentRoute}/${paymentRef}/verify`);

export const postCompleteOnboarding = (company_id: string) =>
  BrassApiService.post(`${onboardingCompleteRoute}`, { for: company_id });
