import { RouteObject, useRoutes, Navigate } from 'react-router-dom';
import {
  authPath,
  dashboardPath,
  keyPersonResponsePath,
  notFoundPagePath,
  onboardingPath,
} from 'src/data/pageRoutes';
import Auth from 'src/modules/auth';

import Onboarding from 'src/modules/onboarding';
import AuthRouteGuard from 'src/sharedComponents/AuthRouteGuard';
import PageNotFound from 'src/sharedComponents/PageNotFound';
import PrivateRouteGuard from 'src/sharedComponents/PrivateRouteGuard';
import Dashboard from 'src/modules/dashboard';
import Invitation from 'src/modules/invitation';

const AppRouter = () => {
  return useRoutes([
    {
      children: [
        {
          index: true,
          element: <Navigate to={onboardingPath} replace />,
        },
        {
          path: `${onboardingPath}/*`,
          element: <PrivateRouteGuard component={Onboarding} />,
        },
        {
          path: `${dashboardPath}/*`,
          element: <PrivateRouteGuard component={Dashboard} />,
        },
        {
          path: keyPersonResponsePath,
          element: <Invitation />,
        },
      ],
    },
    { path: `${authPath}/*`, element: <AuthRouteGuard component={Auth} /> },
    { path: '/*', element: <Navigate to={notFoundPagePath} /> },
    { path: notFoundPagePath, element: <PageNotFound /> },
  ] as RouteObject[]);
};

export default AppRouter;
