import { omit } from 'lodash';
import { Profile } from 'src/types';
import { clearToken, setToken, TokenKey } from 'src/utils/authUtils';

export const sanitizeProfile = <T = any>(profile: any): T =>
  omit(profile, ['token', 'token_expiry']);

export const handleToken = async (profile: any, type: TokenKey) => {
  const { token } = profile;
  await clearToken('*');
  await setToken(type, token);
  const sanitizedProfile = sanitizeProfile<Profile>(profile);
  return sanitizedProfile;
};
