import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { loginPagePath } from 'src/data/pageRoutes';
import { LAUNCHPAD_HOMEPAGE_URL } from 'src/data/env';

import { PageNotFoundIllustration, BrassLogo } from 'src/assets/svgs';
import styles from './PageNotFound.module.scss';

interface PageNotFoundProps {
  className?: string;
}

const PageNotFound: React.FC<PageNotFoundProps> = ({ className }) => {
  return (
    <section className={cx(styles.PageNotFound, className)}>
      <header className={styles.PageNotFound_header}>
        <a href={LAUNCHPAD_HOMEPAGE_URL}>
          <BrassLogo />
        </a>
      </header>
      <div className={styles.PageNotFound_text_box}>
        <h1>The page you are looking for does not exist</h1>
        <p>
          It may have been moved or deleted, or you simply entered a wrong
          address. <Link to={loginPagePath}>Return to login</Link>
        </p>
      </div>
      <PageNotFoundIllustration className={styles.PageNotFound_illustration} />
    </section>
  );
};

export default PageNotFound;
