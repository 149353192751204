import AsyncWrapper from 'src/helpers/asyncWrapper';
import * as api from '../api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Invite, WithData } from 'src/types';

export const fetchInvite = createAsyncThunk<WithData<Invite>, string>(
  'invitation/fetchInvite',
  async (inviteId, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(
      api.getInvite(inviteId, {
        include: 'company,invited_by,nationality',
        useApiKey: true,
      }),
    );
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);
