import {
  ebooksRoute,
  eventsRoute,
  issuesRoute,
  onboardingCompanyRoute,
  rewardsRoute,
  templatesRoute,
} from 'src/data/apiRoutes';
import BrassApiService from 'src/services/brassApiService';
import { DefaultFetchParams, IObject } from 'src/types';
import { IssuesParams, RDTResolveIssue } from './types/requests';
import { BrassCmsService } from 'src/services';

import qs from 'qs';

export const getCompanySummary = (id: string, params: DefaultFetchParams) =>
  BrassApiService.get(`${onboardingCompanyRoute}/${id}`, {
    params,
  });

export const getIssues = (params: IssuesParams) =>
  BrassApiService.get(`${issuesRoute}`, {
    params,
  });

export const patchResolveIssue = ({ id, ...rest }: RDTResolveIssue) =>
  BrassApiService.patch(`${issuesRoute}/${id}`, rest);

export const getDownloadURL = (params: { filename: string }) => {
  return BrassApiService.get(`/files/download-url`, {
    params,
    headers: {
      'Cache-Control': 'no-cache',
    },
  });
};

export const getRewards = (params?: IObject) => {
  return BrassCmsService.get(`${rewardsRoute}`, {
    params,
    paramsSerializer: params =>
      qs.stringify(params, {
        encodeValuesOnly: true,
      }),
  });
};

export const getReward = (rewardId: string) => {
  return BrassCmsService.get(`${rewardsRoute}/${rewardId}`, {
    params: { populate: '*' },
  });
};

export const getTemplates = (params?: IObject) => {
  return BrassCmsService.get(`${templatesRoute}`, {
    params,
    paramsSerializer: params =>
      qs.stringify(params, {
        encodeValuesOnly: true,
      }),
  });
};

export const getEbooks = (params?: IObject) => {
  return BrassCmsService.get(`${ebooksRoute}`, {
    params,
    paramsSerializer: params =>
      qs.stringify(params, {
        encodeValuesOnly: true,
      }),
  });
};

export const getEvents = (params?: IObject) => {
  return BrassCmsService.get(`${eventsRoute}`, {
    params,
    paramsSerializer: params =>
      qs.stringify(params, {
        encodeValuesOnly: true,
      }),
  });
};
