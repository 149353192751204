import { WithData, Embeds, Payload, Amount, IObject } from '../generics';
import { User } from './auth';
export interface Industry {
  id: string;
  name: string;
  created_at: string;
  deleted_at: string;
  updated_at: string | null;
  is_trashed: boolean;
  is_visible: boolean;
  embeds: Embeds;
}

export interface CompanyType {
  id: string;
  label: string;
  embeds: Embeds;
  created_at: string;
  is_trashed: boolean;
  display_name: string;
  price: Amount;
  discount_price: Amount | null;
  deleted_at: string | null;
  updated_at: string | null;
  minimum_share_capital: number | null;
  requires_foreign_shareholder: boolean;
}

export interface State {
  id: string;
  name: string;
  country: WithData<Country>;
  dialing_code: string | null;
  iso_code: string | null;
  updated_at: string | null;
  created_at: string;
  deleted_at: string;
  is_trashed: boolean;
  is_visible: boolean;
  embeds: Embeds;
}

export interface Country {
  id: string;
  name: string;
  states_count: number;
  default_currency: string;
  dialing_code: string | null;
  iso_code: string | null;
  updated_at: string | null;
  created_at: string;
  deleted_at: string;
  is_trashed: boolean;
  is_visible: boolean;
  allows_onboarding: boolean;
  embeds: Embeds;
}

export interface CommonModule {
  statesList: Payload<Array<State>>;
  countriesList: Payload<Array<Country>>;
  industries: Payload<Array<Industry>>;
  companyTypes: Payload<Array<CompanyType>>;
}

export enum KeyPeople {
  Proprietor = 'proprietor',
  Shareholder = 'shareholder',
  Director = 'director',
  Secretary = 'secretary',
  Trustee = 'trustee',
}

export enum CompanyTypes {
  LLC = 'limited_liability_company',
  BusinessName = 'business_name',
  BusinessNameUpgrade = 'business_name_upgrade',
  IncorporatedTrustees = 'incorporated_trustees',
}

export interface CompanyInformation {
  id: string;
  name: string;
  phone: string;
  embeds: Embeds;
  shares: number;
  price: number;
  updated_at: string;
  created_at: string;
  description: string;
  is_trashed: boolean;
  disabled_at: string;
  business_id?: string;
  bn_number: string | null;
  proposed_names: string[];
  onboarding_completed_at: string;
  is_onboarding_completed: boolean;
  objectives: string | null;
  constitution: string | null;
  constitution_filename: string | null;
  miscellaneous_document: string | null;
  business_name_certificate: string | null;
  letter_of_consent: string | null;
  industry?: WithData<Industry>;
  company_type: WithData<CompanyType>;
  address?: WithData<CompanyAddress>;
  kyc_key_people?: WithData<KeyPerson[]>;
  invites?: WithData<Invite[]>;
  incorporation?: WithData<Incorporation>;
  accounts?: WithData<Account[]>;
  payments?: WithData<Payment[]>;
}

export interface CompanyAddress {
  embeds: Embeds;
  id: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  postal_code: string;
  floor_no: string;
  shop_no: string;
  latitude?: number;
  longitude?: number;
  kyc_verified_at: string;
  is_trashed: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  maps_url: string;
  addressable: WithData<CompanyInformation>;
  state: WithData<State>;
}

export interface Payment {
  embeds: Embeds;
  id: string;
  reference: string;
  currency: string;
  amount: Amount;
  status: PaymentStatus;
  gateway_name: string;
  gateway_code: string | null;
  gateway_message: string | null;
  paid_at: string | null;
  created_at: string;
  updated_at: string;
  payment_link: string;
  is_concluded: boolean;
}

export enum PaymentStatus {
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
}

export interface KeyPerson {
  embeds: Embeds;
  id: string;
  role: string;
  firstname: string;
  lastname: string;
  other_names: string;
  title: string;
  occupation: string;
  company_name: string | null;
  rc_number: string | null;
  email: string;
  phone: string;
  gender: string;
  shares: number;
  is_secretary: boolean;
  is_director: boolean;
  date_of_birth: string;
  signature_filename: string;
  created_at: string;
  updated_at: string;
  is_trashed: boolean;
  deleted_at: string;
  is_chairman?: boolean;
  secretary_type?: string;
  address: WithData<KeyPersonAddress>;
  identity: WithData<KeyPersonIdentity>;
}

export interface KeyPersonAddress {
  embeds: Embeds;
  id: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  postal_code: string;
  floor_no: string;
  shop_no: string;
  latitude?: number;
  longitude?: number;
  kyc_verified_at: string;
  is_trashed: false;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  maps_url: string;
  state: WithData<State>;
}
export interface KeyPersonIdentity {
  embeds: Embeds;
  id: string;
  bvn: string;
  id_type: string;
  id_filename: string;
  id_number: string;
  id_issued_at: string;
  id_expires_at: string;
  resolved_identity_data: null;
  review_status: string;
  review_note: string;
  reviewed_at: string;
  is_trashed: boolean;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  nationality: WithData<Country>;
}

export interface Incorporation {
  id: string;
  registered_name: string | null;
  rc_number: string | null;
  reference: string;
  tin: string | null;
  provider: string;
  status: IncorporationStatus;
  stage: string;
  created_at: string;
  updated_at: string;
  completed_at: string | null;
  documents: IncorporationDocument[];
}

export interface IncorporationDocument {
  filename: string;
  type: string;
}

export enum IncorporationStatus {
  Processing = 'processing',
  Pending = 'pending',
  OnHold = 'on-hold',
  Success = 'success',
}

export interface Account {
  id: string;
  bank: WithData<Bank>;
  number: string;
  name: string;
  status: AccountStatus;
  currency: string;
  provider: string;
  created_at: string;
  updated_at: string;
}

export interface Bank {
  code: string;
  created_at: string;
  deleted_at: string | null;
  embeds: Embeds;
  id: string;
  is_trashed: boolean;
  is_visible: number;
  name: string;
  type: string;
  updated_at: string;
}

export enum AccountStatus {
  Processing = 'processing',
  Success = 'success',
}

export interface Invite {
  embeds: Embeds;
  id: string;
  action: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  message: string;
  status: string;
  role: KeyPeople;
  shares: number;
  nationality?: WithData<Country>;
  is_director?: boolean;
  company?: WithData<CompanyInformation>;
  invited_by?: WithData<User>;
  is_trashed: boolean;
  deleted_at: string;
  created_at: string;
  updated_at: string;
}

export interface CMSMediaItem {
  alternativeText: string;
  caption: string;
  createdAt: string;
  ext: string;
  formats: string[] | null;
  hash: string;
  height: number;
  mime: string;
  name: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata: string | null;
  size: number;
  updatedAt: string;
  url: string;
  width: number;
}

export interface CMSItem<T> {
  id: number;
  attributes: T;
}

export interface CMSListResponse<T> {
  data: CMSItem<T>[] | null;
  loading?: boolean;
  meta?: {
    pagination: {
      page: number;
      total: number;
      pageCount: number;
      pageSize: number;
    };
  };
}

export interface CMSItemResponse<T> {
  data: CMSItem<T>;
  meta: IObject;
}
