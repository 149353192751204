import { validatePhoneNumber } from 'src/utils/genericUtils';
import { object, ref, string } from 'yup';

export const signupValidationSchema = object({
  firstname: string().required('Please enter your first name'),
  lastname: string().required('Please enter your last name'),
  email: string()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),
  phone: validatePhoneNumber('Please provide a valid phone number').required(
    'Please provide a phone number',
  ),
  password: string()
    .min(8, 'Your password must have at least 8 characters')
    .matches(/[A-Z]/, 'Must contain at least one uppercase')
    .matches(/[a-z]/, 'Must contain at least one lowercase')
    .matches(/[0-9]/, 'Must contain at least one digit')
    .matches(
      /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/,
      'Must contain at least one special case character',
    )
    .matches(/^[\s\S]{8,}$/, 'Must contain at least 8 characters')
    .required('You must enter a password'),
});

export const loginValidationSchema = object({
  email: string()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),
  password: string()
    .min(8, 'Your password must have at least 8 characters')
    .required('Please enter your password'),
});

export const resetPasswordValidationSchema = object({
  email: string()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),
});

export const setPasswordValidationSchema = object({
  password: string()
    .min(8, 'Your password must have at least 8 characters')
    .matches(/[A-Z]/, 'Must contain at least one uppercase')
    .matches(/[a-z]/, 'Must contain at least one lowercase')
    .matches(/[0-9]/, 'Must contain at least one digit')
    .matches(
      /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/,
      'Must contain at least one special case character',
    )
    .matches(/^[\s\S]{8,}$/, 'Must contain at least 8 characters')
    .required('You must enter a password'),
  confirmpassword: string()
    .required('Please retype your password.')
    .oneOf([ref('password')], 'Your passwords must match'),
});
