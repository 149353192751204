import styles from './TimeoutComponentLayout.module.scss';
import { FunctionalComponent } from 'src/types';
import Navbar from '../../components/Navbar';

const TimeoutComponentLayout: FunctionalComponent = ({ children }) => {
  return (
    <div className={styles.TimeoutComponentLayout}>
      <Navbar className={styles.TimeoutComponentLayout_navbar} />
      {children}
    </div>
  );
};

export default TimeoutComponentLayout;
