import { FC, PropsWithChildren } from 'react';
import { BrassLogo } from 'src/assets/svgs';

import styles from './Navbar.module.scss';

const Navbar: FC<PropsWithChildren<any>> = ({ children }) => {
  return (
    <header className={styles.header}>
      <BrassLogo />
      {children}
    </header>
  );
};

export default Navbar;
