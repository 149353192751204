import { createAsyncThunk } from '@reduxjs/toolkit';
import asyncWrapper from 'src/helpers/asyncWrapper';
import { CMSListResponse } from 'src/types';
import { Faq } from '../types';
import * as api from '../api';

export const fetchDashboardFaq = createAsyncThunk<CMSListResponse<Faq>>(
  'faq/fetchDashboardFaq',
  async (_, { rejectWithValue }) => {
    const [data, error] = await asyncWrapper(
      api.getFaq({
        populate: '*',
        filters: {
          context: {
            slug: {
              $eq: 'launchpad-dashboard',
            },
          },
        },
      }),
    );
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);
