import AsyncWrapper from 'src/helpers/asyncWrapper';
import * as api from '../api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchStatesList = createAsyncThunk(
  'common/fetchStatesList',
  async (_, { rejectWithValue }) => {
    const [states, error] = await AsyncWrapper(
      api.getStatesList({
        country: 'NG',
        limit: 1000,
        useApiKey: true,
        include: 'country',
      }),
      () => null,
    );
    if (error) return rejectWithValue(error.response?.data);
    return states;
  },
);

export const fetchCountriesList = createAsyncThunk(
  'common/fetchCountriesList',
  async (_, { rejectWithValue }) => {
    const [countries, error] = await AsyncWrapper(
      api.getCountriesList({
        useApiKey: true,
        limit: 1000,
      }),
      () => null,
    );
    if (error) return rejectWithValue(error.response?.data);
    return countries;
  },
);

export const fetchIndustries = createAsyncThunk(
  'common/fetchIndustries',
  async (_, { rejectWithValue }) => {
    const [industries, error] = await AsyncWrapper(
      api.getIndustries({
        useApiKey: true,
        limit: 1000,
      }),
      () => null,
    );
    if (error) return rejectWithValue(error.response?.data);
    return industries;
  },
);

export const fetchCompanyTypes = createAsyncThunk(
  'common/fetchCompanyTypes',
  async (_, { rejectWithValue }) => {
    const [industries, error] = await AsyncWrapper(
      api.getCompanyTypes({
        limit: 1000,
      }),
      () => null,
    );
    if (error) return rejectWithValue(error.response?.data);
    return industries;
  },
);
