import {
  postNewUser,
  patchResendVerification,
  postChangePassword,
  patchVerifyEmail,
  postResetPassword,
  postLogin,
} from './api';

import AsyncWrapper from 'src/helpers/asyncWrapper';
import {
  ATRegisterNewUser,
  ATResendVerification,
  ATChangePassword,
  ATVerifyEmail,
  ATResetPassword,
  ATRefreshSession,
} from './types/actions';
import { handleToken } from './utils';

export const registerNewUser: ATRegisterNewUser = async (user, successCb) => {
  const [data] = await AsyncWrapper(postNewUser(user, { useApiKey: true }));
  data && successCb();
};

export const resendVerificationEmail: ATResendVerification = async (
  email,
  successCb,
) => {
  const [data] = await AsyncWrapper(patchResendVerification(email));
  data && successCb();
};

export const changePassword: ATChangePassword = async (user, successCb) => {
  const [data] = await AsyncWrapper(
    postChangePassword(user, { useApiKey: true }),
  );
  data && successCb();
};

export const verifyEmail: ATVerifyEmail = async (hash, successCb) => {
  const [data] = await AsyncWrapper(
    patchVerifyEmail(hash, { useApiKey: true }),
  );
  successCb(!!data);
};

export const resetPassword: ATResetPassword = async (email, successCb) => {
  const [response] = await AsyncWrapper(postResetPassword(email));
  response && successCb();
};

export const refreshSessionToken: ATRefreshSession = async (user, cb) => {
  const [profile] = await AsyncWrapper(
    postLogin(user, {
      useApiKey: true,
    }),
  );

  if (profile) {
    await handleToken(profile, 'auth');
    cb();
  }
};
