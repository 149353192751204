import { ComponentType, FC, Suspense, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { appRootUrl } from 'src/data/pageRoutes';
import { useStoreSelector } from 'src/redux/hooks';
import PageLoader from '../PageLoader';

interface AuthRouteGuardProps {
  component: ComponentType<any>;
}

const AuthRouteGuard: FC<AuthRouteGuardProps> = ({ component: Component }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isLoggedIn = useStoreSelector(state => state['module/auth'].isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      const redirectFromParams = searchParams.get('redirect');
      const pathname = redirectFromParams || appRootUrl;
      searchParams.delete('redirect');
      const search = searchParams.toString();
      navigate({
        pathname,
        search,
      });
    }
  }, [isLoggedIn]);
  return (
    <Suspense fallback={<PageLoader />}>
      <Component />
    </Suspense>
  );
};

export default AuthRouteGuard;
