import { onboardingInviteRoute } from 'src/data/apiRoutes';
import BrassApiService from 'src/services/brassApiService';
import { InviteFetchParams, RDTAcceptInvite } from './types';

export const getInvite = (id: string, params: InviteFetchParams) =>
  BrassApiService.get(`${onboardingInviteRoute}/${id}`, {
    params,
  });

export const patchAcceptInvite = (
  { id, ...rest }: RDTAcceptInvite,
  params: InviteFetchParams,
) => BrassApiService.patch(`${onboardingInviteRoute}/${id}`, rest, { params });
