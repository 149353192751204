import React from 'react';
import { X } from 'react-feather';
import { Accordion, AccordionItem } from 'brass-ui-kit';

import { Flyout } from 'src/sharedComponents/Flyout';

import styles from './FaqFlyout.module.scss';
import { CMSItem } from 'src/types';
import { Faq } from '../../types';

interface FaqFlyoutProps {
  isOpen: boolean;
  data: CMSItem<Faq>[];
  handleClose: () => void;
}

const FaqFlyout: React.FC<FaqFlyoutProps> = ({ isOpen, data, handleClose }) => {
  return (
    <Flyout
      show={isOpen}
      handleClose={handleClose}
      title='Frequently asked questions'
      contentClassName={styles.FaqFlyout_content}
      className={styles.FaqFlyout}
    >
      <div className={styles.FaqFlyout_mobile}>
        <X onClick={handleClose} className={styles.FaqFlyout_mobile_close} />
        <h4 className={styles.FaqFlyout_mobile_title}>
          Frequently asked questions
        </h4>
      </div>
      <Accordion>
        {data &&
          data.map((item, i) => {
            const { answer, question } = item.attributes;
            return (
              <AccordionItem
                header={question}
                body={answer}
                key={i}
                className={styles.FaqFlyout_content_item}
              />
            );
          })}
      </Accordion>
    </Flyout>
  );
};

export default FaqFlyout;
