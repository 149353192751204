import { faqRoute } from 'src/data/apiRoutes';

import { BrassCmsService } from 'src/services';
import { IObject } from 'src/types';
import qs from 'qs';

export const getFaq = (params: IObject) => {
  return BrassCmsService.get(`${faqRoute}`, {
    params,
    paramsSerializer: params =>
      qs.stringify(params, {
        encodeValuesOnly: true,
      }),
  });
};
