import { createSlice } from '@reduxjs/toolkit';
import { ReduxStore } from 'src/types/redux';
import {
  fetchCompanyTypes,
  fetchCountriesList,
  fetchIndustries,
  fetchStatesList,
} from './actions';

type CommonReducerState = ReduxStore['module/common'];

const initialState: CommonReducerState = {
  statesList: {
    data: [],
  },
  countriesList: {
    data: [],
  },
  industries: { data: [] },
  companyTypes: { data: [], loading: true },
};

const commonSlice = createSlice({
  name: 'module/common',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchStatesList.fulfilled, (state, action) => {
      state.statesList.data = action.payload?.data;
    });
    builder.addCase(fetchStatesList.rejected, state => {
      state.statesList.data = initialState.statesList.data;
    });
    builder.addCase(fetchCountriesList.fulfilled, (state, action) => {
      state.countriesList.data = action.payload?.data;
    });
    builder.addCase(fetchCountriesList.rejected, state => {
      state.countriesList.data = initialState.countriesList.data;
    });
    builder.addCase(fetchIndustries.fulfilled, (state, action) => {
      state.industries.data = action.payload?.data;
    });
    builder.addCase(fetchIndustries.rejected, state => {
      state.industries.data = initialState.industries.data;
    });
    builder.addCase(fetchCompanyTypes.pending, state => {
      state.companyTypes.loading = true;
    });
    builder.addCase(fetchCompanyTypes.fulfilled, (state, action) => {
      state.companyTypes.data = action.payload?.data;
      state.companyTypes.loading = false;
    });
    builder.addCase(fetchCompanyTypes.rejected, state => {
      state.companyTypes.data = initialState.companyTypes.data;
      state.companyTypes.loading = false;
    });
  },
});

export const commonReducer = commonSlice.reducer;
