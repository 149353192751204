import AsyncWrapper from 'src/helpers/asyncWrapper';
import * as api from '../api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CMSListResponse,
  CompanyInformation,
  IObject,
  WithData,
} from 'src/types';
import { BBCEvent, Ebook, Issue, Reward, Template } from '../types';

export const fetchIncorporationIssues = createAsyncThunk<
  WithData<Issue[]>,
  string
>(
  'dashboard/fetchIncorporationIssues',
  async (incorporationId, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(
      api.getIssues({
        incorporation: incorporationId,
        statuses: 'open',
      }),
    );
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);

export const fetchCompany = createAsyncThunk<
  WithData<CompanyInformation>,
  string
>('dashboard/fetchCompany', async (company, { rejectWithValue, dispatch }) => {
  const [data, error] = await AsyncWrapper(
    api.getCompanySummary(company, {
      include: 'invites,kyc_key_people,address,industry,incorporation,accounts',
    }),
  );
  if (error) return rejectWithValue(error.response?.data);
  if (data.data.incorporation) {
    await dispatch(fetchIncorporationIssues(data.data.incorporation.data.id));
  }
  return data;
});

export const fetchRewards = createAsyncThunk<CMSListResponse<Reward>, IObject>(
  'dashboard/fetchRewards',
  async (filters, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(
      api.getRewards({ populate: 'companyLogo', ...filters }),
    );
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);

export const fetchTemplates = createAsyncThunk<
  CMSListResponse<Template>,
  IObject
>('dashboard/fetchTemplates', async (filters, { rejectWithValue }) => {
  const [data, error] = await AsyncWrapper(
    api.getTemplates({ populate: 'icon', ...filters }),
  );
  if (error) return rejectWithValue(error.response?.data);
  return data;
});

export const fetchEbooks = createAsyncThunk<CMSListResponse<Ebook>, IObject>(
  'dashboard/fetchEbooks',
  async (filters, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(
      api.getEbooks({ populate: 'image', ...filters }),
    );
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);

export const fetchEvents = createAsyncThunk<CMSListResponse<BBCEvent>>(
  'dashboard/fetchEvents',
  async (_, { rejectWithValue }) => {
    const [data, error] = await AsyncWrapper(api.getEvents({ populate: '*' }));
    if (error) return rejectWithValue(error.response?.data);
    return data;
  },
);
