import { PropsWithChildren, useEffect, FC, useCallback } from 'react';
import { batch } from 'react-redux';
import { useStoreDispatch, useStoreSelector } from './../redux/hooks';
import {
  fetchStatesList,
  fetchCountriesList,
  fetchIndustries,
} from 'src/modules/common';
import { getToken } from 'src/utils/authUtils';
import { fetchActiveSession } from 'src/modules/auth/redux';
import PageLoader from 'src/sharedComponents/PageLoader';

const AppInitializer: FC<PropsWithChildren<any>> = ({ children }) => {
  const isAuthTokenInStorage = Boolean(getToken('auth'));

  const dispatch = useStoreDispatch();
  const { hasSessionTimedOut, isLoggedIn, isFetchingSession } =
    useStoreSelector(state => state['module/auth']);

  const initializeUserData = useCallback(() => {
    if (!hasSessionTimedOut && isLoggedIn && isAuthTokenInStorage) {
      dispatch(fetchActiveSession());
    }
  }, [isLoggedIn, hasSessionTimedOut, isAuthTokenInStorage]);

  useEffect(() => {
    batch(() => {
      dispatch(fetchStatesList());
      dispatch(fetchCountriesList());
      dispatch(fetchIndustries());
    });
  }, [dispatch]);

  useEffect(() => {
    initializeUserData();
  }, [hasSessionTimedOut, isLoggedIn, isAuthTokenInStorage]);

  return isFetchingSession ? <PageLoader /> : <>{children}</>;
};

export default AppInitializer;
