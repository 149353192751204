import { Integrations as SentryIntegrations } from '@sentry/tracing';
import pkg from '../../package.json';
import {
  SENTRY_KEY,
  SENTRY_TRACES_SAMPLE_RATE,
  SENTRY_ENV,
} from 'src/data/env';

export const SENTRY_CONFIG = {
  dsn: SENTRY_KEY,
  environment: SENTRY_ENV,
  release: `${pkg.name}@${pkg.version}`,
  integrations: [new SentryIntegrations.BrowserTracing()],
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
};
