import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KeyPeople, ReduxStore } from 'src/types';
import { isLogoutAction } from 'src/utils/authUtils';
import {
  fetchCompany,
  fetchEbooks,
  fetchEvents,
  fetchIncorporationIssues,
  fetchRewards,
  fetchTemplates,
} from './actions';
import { PARTNER_TYPES } from 'src/data/constants';

type DashboardReducerState = ReduxStore['module/dashboard'];

const initialState: DashboardReducerState = {
  company: {
    data: null,
    loading: true,
  },
  issues: null,
  rewards: {
    data: null,
    loading: true,
  },
  templates: {
    data: null,
    loading: true,
  },
  ebooks: {
    data: null,
    loading: true,
  },
  events: {
    data: null,
    loading: true,
  },
  hasDownloadedEbook: false,
};

const dashboardSlice = createSlice({
  name: 'module/dashboard',
  initialState,
  reducers: {
    setHasDownloadedEbook: (state, action: PayloadAction<boolean>) => {
      state.hasDownloadedEbook = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCompany.pending, (state, action) => {
        state.company.loading = true;
      })
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.company = {
          ...state.company,
          data: action.payload.data,
          loading: false,
        };
      })
      .addCase(fetchCompany.rejected, (state, action) => {
        state.company = {
          ...state.company,
          loading: false,
        };
      })
      .addCase(fetchIncorporationIssues.fulfilled, (state, action) => {
        state.issues = action.payload.data;
      })
      .addCase(fetchRewards.pending, state => {
        state.rewards = { ...state.rewards, loading: true };
      })
      .addCase(fetchRewards.rejected, state => {
        state.rewards = { ...state.rewards, loading: false };
      })
      .addCase(fetchRewards.fulfilled, (state, action) => {
        state.rewards = { ...state.rewards, ...action.payload, loading: false };
      })
      .addCase(fetchTemplates.pending, state => {
        state.templates = { ...state.templates, loading: true };
      })
      .addCase(fetchTemplates.rejected, state => {
        state.templates = { ...state.templates, loading: false };
      })
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.templates = {
          ...state.templates,
          ...action.payload,
          loading: false,
        };
      })
      .addCase(fetchEbooks.pending, state => {
        state.ebooks = { ...state.ebooks, loading: true };
      })
      .addCase(fetchEbooks.rejected, state => {
        state.ebooks = { ...state.ebooks, loading: false };
      })
      .addCase(fetchEbooks.fulfilled, (state, action) => {
        state.ebooks = {
          ...state.ebooks,
          ...action.payload,
          loading: false,
        };
      })
      .addCase(fetchEvents.pending, state => {
        state.events = { ...state.events, loading: true };
      })
      .addCase(fetchEvents.rejected, state => {
        state.events = { ...state.events, loading: false };
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = {
          ...state.events,
          ...action.payload,
          loading: false,
        };
      });
    builder.addMatcher(isLogoutAction, () => initialState);
  },
});

export const selectKeyPeople = (state: ReduxStore) => {
  const directors =
    state['module/dashboard'].company.data?.kyc_key_people?.data?.filter(
      person => person.is_director,
    ) || [];
  const partners =
    state['module/dashboard'].company.data?.kyc_key_people?.data?.filter(
      person => PARTNER_TYPES.includes(person.role as KeyPeople),
    ) || [];
  return {
    directors,
    partners,
  };
};

export const dashboardReducer = dashboardSlice.reducer;
