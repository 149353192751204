import { configureStore } from '@reduxjs/toolkit';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import logger from 'redux-logger';
import { createReduxEnhancer as SentryCreateReduxEnhancer } from '@sentry/react';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { REDUX_PERSIST_CONFIG } from 'src/config/reduxPersist';

import { ReduxStore } from 'src/types/redux';
import reducers from './reducers';

const persistedReducer = persistReducer<ReduxStore>(
  REDUX_PERSIST_CONFIG,
  reducers,
);

const sentryReduxEnhancer = SentryCreateReduxEnhancer();

const middlewareConfig = (
  getDefaultMiddleware: CurriedGetDefaultMiddleware<
    ReduxStore & PersistPartial
  >,
) => {
  const isInDevelopmentMode = process.env.NODE_ENV === 'development';
  const options = {
    immutableCheck: false,
    serializableCheck: false,
  };
  if (isInDevelopmentMode) return getDefaultMiddleware(options).concat(logger);
  return getDefaultMiddleware(options);
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewareConfig,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: defaultEnhancers => [sentryReduxEnhancer, ...defaultEnhancers],
});

export const persistor = persistStore(store);
