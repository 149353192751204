import React from 'react';
import {
  ErrorBoundary as SentryErrorBoundary,
  withProfiler,
} from '@sentry/react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from 'src/sharedComponents/ErrorBoundary';
import PageLoader from 'src/sharedComponents/PageLoader';
import { store, persistor } from 'src/redux/store';
import { PersistGate } from 'redux-persist/integration/react';

import AppInitializer from './AppInitializer';
import AppRouter from './AppRouter';

import 'src/styles/main.scss';
import 'brass-ui-kit/dist/styles/brass-dark.css';
import { ToastContainer } from 'brass-ui-kit';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={<PageLoader />} persistor={persistor}>
          <SentryErrorBoundary fallback={<ErrorBoundary />}>
            <React.Suspense fallback={<PageLoader />}>
              <AppInitializer>
                <AppRouter />
              </AppInitializer>
            </React.Suspense>
          </SentryErrorBoundary>
          <ToastContainer />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
};

export default withProfiler(App);
