import { ButtonVariant } from 'brass-ui-kit';
import { ButtonHTMLAttributes, FC, PropsWithChildren, ReactNode } from 'react';

export interface IObject<T = any> {
  [x: string]: T;
}

export type File = {
  key?: string;
  url: string;
  content_type?: string;
};

export type Embeds = Array<string>;

export type WithData<T> = {
  data: T;
};

export type DropDownOption = {
  value?: string | number;
  icon?: any;
  label: ReactNode;
  handleClick: () => void;
  isHidden?: boolean;
};

export type Pagination = {
  current_page: number;
  per_page: number;
  total_pages: number;
  total?: number;
};

export type Payload<D, M = any> = {
  data: D;
  loading?: boolean;
  error?: string;
  pagination?: Pagination;
  meta?: M;
};

export type TFunc<V, R = void> = (x: V) => R;

export type FunctionalComponent<T = any> = FC<PropsWithChildren<T>>;

export type ButtonProps = {
  label?: string;
  size?: 'full' | 'default';
  props?: {
    disabled?: boolean;
    loading?: boolean;
    variant?: ButtonVariant;
  } & ButtonHTMLAttributes<any>;
};

export type FooterProps = {
  primary?: ButtonProps;
  secondary?: ButtonProps;
};

export interface DialogProps<T = any, R = any> {
  show: boolean;
  handleClose?: TFunc<boolean>;
  title: ReactNode;
  description: ReactNode;
  data: T;
  action: {
    variant: ButtonVariant;
    label: string;
    size?: ButtonProps['size'];
  };
  successMessage?: string;
  request: R;
  refresh?: () => void;
}

export type DialogRequest = (
  id: string,
  cb: TFunc<boolean>,
  body?: any,
) => Promise<any>;

export interface DefaultFetchParams {
  page?: number;
  limit?: number;
  include?: string;
  statuses?: string;
  from_date?: string;
  to_date?: string;
}

export interface MessageResponse {
  message: string;
}

export enum StatusTypes {
  Success = 'success',
  Pending = 'pending',
  Failed = 'failed',
}

export interface Amount {
  raw: string;
  formatted: string;
  formatted_display: string;
}

export interface AddRowToSheet {
  baseName: string;
  data: IObject;
}

export interface AddToCalendar {
  title: string;
  description?: string;
  start: Date;
  end?: Date;
}
